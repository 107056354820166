import React from "react";
import PrimaryButton from './PrimaryButton';

const DeleteItemFromListModal = (props) => {

    return (
        <div className={`modal ${props.visible === true ? 'is-active' : ''}`}>
            <div className="modal-background "></div>
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">Delete Item</p>
                    <button className="delete" aria-label="close" onClick={props.onDismiss} />
                </header>
                <section className="modal-card-body">
                    <p>Are you sure you want to remove <bold>{props.item.name}</bold>  from your list?</p>
                </section>
                <footer className="modal-card-foot">
                    <PrimaryButton
                        text="Delete"
                        onClick={() => {
                            props.onRemoveClicked(props.item);
                            props.onDismiss();
                        }} />
                    <button className="button" onClick={props.onDismiss}>
                        Cancel
                    </button>
                </footer>
            </div>
        </div>
    );
};

export default DeleteItemFromListModal;
