import React, { useState, useContext, useEffect } from "react";
import ShoppingListService from "../services/ShoppingListService";
import { PageHeader, NoDataCard } from "../components";
import { Link } from "react-router-dom";
const HomePage = (props) => {

    const [lists, setLists] = useState([]);

    const [loading, setLoading] = useState(true);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        const init = async () => {
            await _getUserListsAsync();
        };
        init();
    }, []);

    const _getUserListsAsync = async () => {
        try {
            setLoading(true);

            // Check if first run, then wait
            const lists = await ShoppingListService.getUserShoppingLists();
            setLists(lists);
            console.log(lists);
        } catch (error) {
            setShowErrorMessage(true);
            setErrorMessage(error.message);
        }
        setLoading(false);
    };

    return (
        <>
            <PageHeader
                title={`Lists`}
                loading={loading}
                showErrorMessage={showErrorMessage}
                errorMessage={errorMessage} />

            {
                lists && lists.length > 0 &&
                lists.map((list, i) => (
                    <div key={i}>
                        <Link className="navbar-item" to={`/list/${list.shortShoppingListCode}`}>
                            {list.name}
                        </Link>
                    </div>
                ))
            }
            {
                lists.length === 0 &&
                <NoDataCard
                    noDataText={'You are currently not part of any lists'}
                    loading={loading} />
            }
        </>
    );
};
export default HomePage;