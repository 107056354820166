import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useToasts } from 'react-toast-notifications';
import { isMobile } from 'mobile-device-detect';
import { ShoppingListService, ItemService } from './../services';
import { PageHeader, NoDataCard, DeleteItemFromListModal } from "../components";
import UsersService from './../services/UserService';
const ListsPage = () => {

    const { addToast } = useToasts();

    const { id } = useParams();
    const [list, setList] = useState(null);
    const [itemName, setItemName] = useState("");
    const [items, setItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState({});

    const [loading, setLoading] = useState(true);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [showDeleteItemModal, setShowDeleteItemModal] = useState(false);

    const _getListItemsAsync = async () => {
        try {
            const list = await ShoppingListService.getShoppingListByShortShoppingListCode(id);
            setList(list);
            setItems(list.items);

            setShowErrorMessage(false);
            setErrorMessage("");
            setLoading(false);

            try {
                await UsersService.addUserToShoppingList(list.shortShoppingListCode);
            } catch (error) {
                console.error(error);
            }
        } catch (error) {
            setShowErrorMessage(true);
            setErrorMessage(error.message);
        }
        setLoading(false);
    };

    const onDeleteClickAsync = (item) => {

        setSelectedItem(item);
        setShowDeleteItemModal(true);
    };

    const _onItemCheckboxClickedAsync = async (item) => {
        setLoading(true);
        try {
            item.needed = !item.needed;
            await ItemService.updateItem(item);
            await _getListItemsAsync();
        } catch (error) {
            setShowErrorMessage(true);
            setErrorMessage(error.message);
        }
        setLoading(false);
    };
    const _addItemToListAsync = async () => {
        setLoading(true);
        try {

            const item = {
                name: itemName,
                notes: "",
                url:'',
                quantity: 1,
                shoppingListId: list.id,
                needed: true
            };

            await ItemService.createItem(item);

            addToast(`${itemName} added to ${list.name}`, { appearance: 'success' });
            setItemName("");
            await _getListItemsAsync();
        } catch (error) {
            setShowErrorMessage(true);
            setErrorMessage(error.message);
        }
        setLoading(false);
    };

    useEffect(() => {
        const init = async () => {
            await _getListItemsAsync();
        };
        init();
    }, []);

    const openInMobileApp = () => {
        try {
            const locationUrl = `listhubx://join?code=${id}`;
            window.location = locationUrl;
            // var frame = document.createElement('iframe');
            // frame.src = `listhub://join?code=${id}`;
            // frame.style.display = 'none';
            // document.body.appendChild(frame);

            // // the following is optional, just to avoid an unnecessary iframe on the page
            // setTimeout(function () { document.body.removeChild(frame); }, 4);
        } catch (error) {
            console.log(error);
        }
    };

    const _onRemoveItemClickedAsync = async (item) => {
        console.log(item);
        setLoading(true);
        await ItemService.deleteItem(item);
        await _getListItemsAsync();
    };


    return (
        <>
            {
                isMobile &&
                <div className="columns  mb-5 is-centered">
                    <button
                        className={`button is-dark ${loading ? 'is-loading' : ''}`}
                        onClick={openInMobileApp}
                        disabled={loading}>
                        <div className="button-bold">Do you have ListHubX installed? Join this list on the app</div>
                    </button>
                </div>
            }
            <PageHeader
                title={!!list ? list.name : ''}
                loading={loading}
                showErrorMessage={showErrorMessage}
                errorMessage={errorMessage} />

            <>
                {
                    list != null &&
                    <section className="hero main-body-container">
                        <div className="hero-body">
                            <div className="columns">
                                <div className="column is-9">
                                    <input
                                        className="input is-large"
                                        value={itemName}
                                        onChange={(e) => { setItemName(e.target.value); }}
                                        onKeyPress={async (e) => e.key === 'Enter' && await _addItemToListAsync()}
                                        type="text"
                                        placeholder={`Add item to ${!!list ? list.name : ''}`} />
                                </div>
                                <div className="column is-3">
                                    <button
                                        className={`button is-fullwidth is-info is-large ${loading ? 'is-loading' : ''}`}
                                        onClick={_addItemToListAsync}
                                        disabled={loading || list == null || itemName.length <= 0}>
                                        <span className="icon">
                                            <i className="fas fa-plus"></i>
                                        </span>
                                        <span>Add Item</span>
                                    </button>
                                </div>
                            </div>
                            {
                                items && items.length > 0 &&
                                <div className="items-container">
                                    {
                                        items.map((item, i) => (
                                            <div key={i} className="item-box">
                                                <div className="columns is-flex-mobile is-flex-tablet">
                                                    <div className="column is-10 is-pulled-left">
                                                        <label className="checkbox">
                                                            <input
                                                                type="checkbox"
                                                                className="item-checkbox"
                                                                checked={!item.needed}
                                                                onChange={() => { _onItemCheckboxClickedAsync(item); }} />
                                                            <span className={`item-name ${!item.needed ? 'strike-through' : ''} `} >
                                                                {item.name}
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div className="column is-2 is-pulled-right">
                                                        <button
                                                            onClick={async () => { await onDeleteClickAsync(item); }}
                                                            className="button is-small is-danger is-outlined is-pulled-right">
                                                            <span className="icon is-small">
                                                                <i className="fas fa-trash-alt"></i>
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            }
                            {
                                items.length === 0 &&
                                <NoDataCard
                                    loading={loading}
                                    noDataText={'There are currently no items added to this list'} />
                            }
                        </div>

                        <DeleteItemFromListModal
                            onDismiss={() => { setShowDeleteItemModal(false); }}
                            onRemoveClicked={_onRemoveItemClickedAsync.bind(this)}
                            visible={showDeleteItemModal}
                            item={selectedItem} />
                    </section>

                }
            </>
        </>
    );
};
export default ListsPage;