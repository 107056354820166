import { v4 as uuidv4 } from 'uuid';

const Lib = {
    generateGuid() {
        return uuidv4();
    },

    wait(ms) {
        var start = new Date().getTime();
        var end = start;
        while (end < start + ms) {
            end = new Date().getTime();
        }
    },
};

export default Lib;