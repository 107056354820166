import Config from '../Config';
import UserStorageUtility from '../utilities/UserStorageUtility';
import Lib from '../utilities/Lib';
import StorageKeysEnums from './../enums/StorageKeysEnum';

const UsersService = {

    async getUser() {
        let user = { id: '', platform: '' };

        if (!UserStorageUtility.hasUserAccount()) {
            user = await this.createUser();
        }
        else {
            user = UserStorageUtility.getUser();
        }

        return user;
    },

    async createUser() {
        const url = `${Config.apiUrl}/Users/Create`;

        const userId = Lib.generateGuid();
        const user = { id: userId, platform: 2 };

        const response = await fetch(url, {
            method: "post",
            body: JSON.stringify(user),
            headers: {
                "Content-Type": "application/json"
            }
        });

        const json = await response.json();

        if (response.status !== 200) {
            throw new Error(
                json.message ? json.message : 'Unable to create user'
            );
        }

        UserStorageUtility.saveUser(user);

        
        return user;
    },

    async addUserToShoppingList(shortShoppingListCode) {

        const listStorageName = `${StorageKeysEnums.LIST_PREFIX}${shortShoppingListCode}`;
        if(localStorage.getItem(listStorageName) !== null){
            return;
        }

        const url = `${Config.apiUrl}/UserShoppingLists/JoinShoppingList`;
        const user = await this.getUser();

        const userShoppingList = {
            userId: user.id,
            shortShoppingListCode
        };

        const response = await fetch(url, {
            method: "post",
            body: JSON.stringify(userShoppingList),
            headers: {
                "Content-Type": "application/json"
            }
        });

        const json = await response.json();

        localStorage.setItem(listStorageName,"true");

        if (response.status !== 200) {
            throw new Error(
                json.message ? json.message : 'Unable to create user'
            );
        }

        UserStorageUtility.saveUser(user);

        
        return user;
    }



};

export default UsersService;