import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AddNewListModal from './AddNewListModal';
import { UsersService } from "../services";

const Navbar = () => {

    const [showAddNewListModal, setShowAddNewListModal] = useState(false);

    const _initializeNavbar = () => {
        const $navbarBurgers = Array.prototype.slice.call(
            document.querySelectorAll(".navbar-burger"),
            0
        );
        if ($navbarBurgers.length > 0) {
            $navbarBurgers.forEach((el) => {
                el.addEventListener("click", () => {
                    const target = el.dataset.target;
                    const $target = document.getElementById(target);
                    el.classList.toggle("is-active");
                    $target.classList.toggle("is-active");
                });
            });
        }
    };

    useEffect(() => {
        const init = async () => {
            await UsersService.getUser();
        };
        _initializeNavbar();
        init();
    }, []);

    return (
        <nav className="navbar is-info nav-bar" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
                <Link className="navbar-item" to="/">
                    <img src="assets/images/logo.png" alt="logo" />
                </Link>

                {
                    // eslint-disable-next-line
                    <a
                        role="button"
                        className="navbar-burger burger"
                        aria-label="menu"
                        aria-expanded="false"
                        data-target="navbarBasicExample"
                    >
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </a>
                }
            </div>

            <div id="navbarBasicExample" className="navbar-menu">
                <div className="navbar-end">
                    <div className="navbar-item">
                        <div className="buttons">
                            <button
                                className="button is-white"
                                onClick={() => { setShowAddNewListModal(true); }} >
                                <strong>Create New List</strong>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <AddNewListModal
                visible={showAddNewListModal}
                onDismiss={() => { setShowAddNewListModal(false); }}
            />
        </nav>
    );
};
export default Navbar;