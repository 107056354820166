
import Config from '../Config';
import UserStorageUtility from '../utilities/UserStorageUtility';


const ItemService = {
    async createItem(item) {

        const user = await UserStorageUtility.getUser();
        item.createdBy = user.id;

        const apiUrl = `${Config.apiUrl}/Items/CreateItem`;
        const body = JSON.stringify(item);

        const response = await fetch(apiUrl, {
            method: "post",
            body,
            headers: {
                "Content-Type": "application/json"
            }
        });

        const json = await response.json();

        if (response.status !== 200) {
            throw new Error(json.message ? json.message : 'Unable to create shopping list');
        }

        return json;
    },

    async updateItem(item) {
        const apiUrl = `${Config.apiUrl}/Items/UpdateItem/${item.id}`;

        const body = JSON.stringify(item);

        const response = await fetch(apiUrl, {
            method: "put",
            body,
            headers: {
                "Content-Type": "application/json"
            }
        });

        const json = await response.json();

        if (response.status != 200) {
            throw new Error(json.message ? json.message : 'Unable to update item');
        }
    },

    async deleteItem(item) {
        const apiUrl = `${Config.apiUrl}/Items/DeleteItem/${item.id}`;

        const response = await fetch(apiUrl, {
            method: "delete",
            headers: {
                "Content-Type": "application/json"
            }
        });

        const json = await response.json();

        if (response.status != 200) {
            throw new Error(json.message ? json.message : 'Unable to delete');
        }
    }
};

export default ItemService;