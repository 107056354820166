import StorageKeysEnums from "../enums/StorageKeysEnum";

const UserStorageUtility = {
    saveUser(user) {
        localStorage.setItem(StorageKeysEnums.USER_KEY, JSON.stringify(user));
    },

    getUser() {
        const user = localStorage.getItem(StorageKeysEnums.USER_KEY);
        return JSON.parse(user);
    },

    hasUserAccount() {
        const user = localStorage.getItem(StorageKeysEnums.USER_KEY) || null;
        return !!JSON.parse(user) && JSON.parse(user).id ?
            JSON.parse(user).id.length > 0 : false;
    },

};

export default UserStorageUtility;
