
import Config from '../Config';
import UserStorageUtility from '../utilities/UserStorageUtility';
import StorageKeysEnums from './../enums/StorageKeysEnum';

const ShoppingListService = {
    async createShoppingList(shoppingListName) {

        const user = await UserStorageUtility.getUser();
        const list = {
            name: shoppingListName,
            userId: user.id
        };

        const apiUrl = `${Config.apiUrl}/ShoppingLists/Create`;
        const body = JSON.stringify(list);

        const response = await fetch(apiUrl, {
            method: "post",
            body,
            headers: {
                "Content-Type": "application/json"
            }
        });

        const json = await response.json();

        if (response.status !== 200) {
            throw new Error(json.message ? json.message : 'Unable to create shopping list');
        }

        return json;
    },   

    async getUserShoppingLists(){
        const user = await UserStorageUtility.getUser();
        const apiUrl = `${Config.apiUrl}/ShoppingLists/GetShoppingListByUserId/${user.id}`;

        const response = await fetch(apiUrl, { method: "get" });
        const json = await response.json();

        if (response.status !== 200) {
            throw new Error(json.message ? json.message : 'Unable to get shopping list');
        }

        return json;
    },

    async getShoppingListByShortShoppingListCode(shortListCode){

        if(localStorage.getItem(StorageKeysEnums.FIRST_RUN) === null)
        {
            localStorage.setItem(StorageKeysEnums.FIRST_RUN,"true");
        }

        const base = `${Config.apiUrl}/ShoppingLists/GetShoppingListByShortShoppingListCode`;
        const params = `?shoppingListCode=${shortListCode}`;
        const apiUrl = `${base}${params}`;

        const response = await fetch(apiUrl, { method: "get" });
        const json = await response.json();

        if (response.status !== 200) {
            throw new Error(json.message ? json.message : 'Unable to get shopping list by code');
        }

        return json;
    }
};

export default ShoppingListService;