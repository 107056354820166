import './App.css';
import 'bulma/css/bulma.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { ToastProvider } from 'react-toast-notifications';
import { Route, HashRouter } from "react-router-dom";
import { Navbar, Footer } from "./components";
import { HomePage, ListsPage } from './pages';
import React, { useEffect, useState } from "react";
import { UsersService } from './services';

function App() {

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const init = async () => {
            setLoading(true);
            try {
                // we do this to ensure a user is created as the get user will create a user if no user exists
                await UsersService.getUser();
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        };
        init();
    }, []);


    return (
        <>
            {
                !loading ? <ToastProvider
                    autoDismiss
                    autoDismissTimeout={6000}
                    placement="bottom-left">
                    <div>
                        <HashRouter>
                            <Navbar />
                            <div className="container main-app-body">
                                <div>
                                    <Route exact path="/list/:id" component={ListsPage} />
                                    <Route exact path="/" component={HomePage} />
                                </div>
                            </div>
                            <Footer />
                        </HashRouter>
                    </div>
                </ToastProvider>
                    : null
            }
        </>
    );
}

export default App;
