import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useToasts } from 'react-toast-notifications';
import PrimaryButton from './PrimaryButton';
import PrimaryInput from './PrimaryInput';
import ShoppingListService from "../services/ShoppingListService";

const AddNewListModal = (props) => {

    const { addToast } = useToasts();

    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [listName, setListName] = useState("");

    const _onAddListClickAsync = async () => {
        setLoading(true);

        try {
            
            const list = await ShoppingListService.createShoppingList(listName);
            addToast('List saved successfully', { appearance: 'success' });
            setListName("");
            history.push(`/list/${list.shortShoppingListCode}`);
            location.reload();            
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    };

    return (
        <div className={`modal ${props.visible === true ? 'is-active' : ''}`}>
            <div className="modal-background "></div>
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">Add New List</p>
                    <button className="delete" aria-label="close" onClick={props.onDismiss} />
                </header>
                <section className="modal-card-body">
                    <PrimaryInput
                        placeholder={"List Name"}
                        value={listName}
                        loading={loading}
                        disabled={loading}
                        onChange={(e) => { setListName(e.target.value); }} />
                </section>
                <footer className="modal-card-foot">
                    <PrimaryButton
                        text="Save"
                        disabled={loading || listName.length <= 0}
                        loading={loading}
                        onClick={_onAddListClickAsync} />
                    <button className="button" onClick={props.onDismiss}>
                        Cancel
                    </button>
                </footer>
            </div>
        </div>
    );
};

export default AddNewListModal;
